<template>

<div>
    <v-toolbar-title class='global-main-title'  >Documents de l'intervention</v-toolbar-title>
    <cx-document-galerie class='mt-5' :id="this.dataProvider.intervention" model="interventions" type="pre_intervention" document_libelle="un document"></cx-document-galerie>
    <div class='d-flex justify-space-around'>
        <cx-button label='PRECEDENT' @click='previous'></cx-button>
        <cx-button label='SUIVANT' @click='next' v-if="!dataProvider.clotures"></cx-button>
    </div>
</div>

</template>
<script>
    export default {
        name: 'vx-inter-document',
        props : {
            dataProvider: {}
        },
         components: {
            'CxButton': () => import('../../components/action/CxButton'),
            'CxDocumentGalerie': () => import('../../components/action/CxDocumentGalerie')
        },
        methods:{
            previous(){
                this.$store.commit('TAB_COMPONENT', {component: 'vx-interventions', title: 'Mon Activité'})
            },
            next(){
                this.$store.commit('TAB_COMPONENT', {   component: 'vx-logements', 
                                                        title: 'Intervention ' + this.dataProvider.intervention,
                                                        dataProvider: { "intervention" : this.dataProvider.intervention }
                                                    });
            }
        }
    }
</script>

<template>
    <div>
        <div style='text-align:center;background-color:lightgrey' class='pt-2 pa-2'>{{dataProvider.libelle}}</div>
        <hr>        
    </div>
</template>


<script>

  export default {
        name: "cx-information",
        props: {
            dataProvider: {
                type: Object
                /*
                {
                    label: '',
                    data: ''
                }
                */
            }
        }
    }
</script>

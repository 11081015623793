<!-- SOURCE : https://vuetifyjs.com/en/components/snackbars/#usage -->
<template>
    <v-snackbar
        :style="{'--text-color': textColor}"
        :top="getPosition('top')"
        :centered="getPosition('center')"
        :bottom="getPosition('bottom')"
        class="cx-power-snack-bar__snackbar"
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbarColor"
        @input="close"
    >
        {{ message }}

        <template v-slot:action="{ attrs }">

            <v-btn
                id="test"
                class="cx-power-snack-bar__button"
                :color="buttonColor"
                icon
                v-bind="attrs"
                @click="close"
            >
                <v-icon>mdi-close-thick</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "PowerSnackBar",

    data: () => ({
        snackbar: false,
        message: 'Placeholder',
        timeout: 4500,
        snackbarColor: '#000000',
        buttonColor: '#FFF',
        textColor: '#FFF',
        position: 'bottom'
    }),

    methods: {
        close() {
            this.snackbar = false;
            this.$emit('close');
        },
        getPosition(position) {
            return this.position === position;
        },
        update({
            message= 'Placeholder',
            timeout= 4500,
            snackbarColor= '#000000',
            buttonColor= '#FFF',
            textColor= '#FFF',
            position= 'top'
        }){
            this.message = message
            this.timeout = timeout
            this.snackbarColor = snackbarColor
            this.buttonColor = buttonColor
            this.textColor = textColor
            this.position = position
            this.snackbar = true
        }
    }
}
</script>

<style lang="scss">
    .cx-power-snack-bar__snackbar {
        .v-snack__wrapper {
            color: var(--text-color) !important;
        }
    }

    .cx-power-snack-bar__close-icon {
        height: 24px;
        font-size: 24px;
        width: 24px;
        display: inline-flex;
        align-items: center;
    }
</style>
<template>
<div>
    <v-toolbar-title class='global-main-title' >Historique technique</v-toolbar-title>
    <v-container class='pt- px-6'>
        <div v-for="(intervention,indexInter) in datas" :key="indexInter">
            <v-col style='overflow-wrap:normal;'  >
                <v-row><div class='font-weight-bold mr-2'>APP :</div>  {{intervention.list_affect_appareils}} </v-row>
                <v-row><div class='font-weight-bold mr-2'>DATE :</div>  {{intervention.inter_date}} </v-row>
                <v-row><div class='font-weight-bold mr-2'>AGENT :</div>  {{intervention.techniciens[0].technicien_nom}} </v-row>
                <v-row><div class='font-weight-bold mr-2'>TYPE :</div>  {{intervention.type_cr.cr_code}} </v-row>
                <v-row><div class='font-weight-bold mr-2'>CLOTURE :</div> {{intervention.list_affect_cloture}} </v-row>
                <v-row><div class='font-weight-bold mr-2'>MO :</div>  {{intervention.list_affect_main_oeuvre}} </v-row>
                <v-row><div class='font-weight-bold mr-2'>PM :</div>  {{intervention.list_affect_pieces}} </v-row>
                <cx-button class='mt-5' label='VOIR FICHE INTERVENTION' :disabled='(intervention.intervention_cri == 0) ? true : false' :loading="loading" @click='getFicheIntervention(intervention.inter_id)'></cx-button>
            </v-col>
            <v-divider class='mb-2' ></v-divider> 
           
        </div>
        
        <div class="mb-10"></div>
        <cx-button :customStyle="{left:'0px'}" :bottom="true" :fixed="true" label='SUIVANT' @click='next'></cx-button>
    </v-container>   
</div>
</template>
<script>
    export default {
        name: 'vx-historique-technique',
        components: {
            'CxButton': () => import('../../components/action/CxButton')
        },
        props : {
            dataProvider: {}
        },
        data() {
            return {
                datas : {},
                loading: false
            }
        },
        mounted(){

            this.$services.request.get('api/pwa/mes-interventions/intervention/' + this.dataProvider.intervention + '/historique-technique').then((response)=>{
                this.datas = response
            }).catch(()=> {
                this.$services.powerLib.notification.showSnackbar({
                            message : 'Une erreur est survenue lors de la récupération des informations',
                            type : 'error'
                })
            })
        },
        methods: {
            next(){
                this.$store.commit('TAB_COMPONENT', {   component: 'vx-recapitulatif-operations', 
                                                        title: 'Intervention ' + this.dataProvider.intervention,
                                                        dataProvider: { "intervention" : this.dataProvider.intervention }
                                                    });
            },
            getFicheIntervention(id){
                this.loading = true;
            
                this.$services.request.openPdf('api/pwa/mes-interventions/intervention/' + id )
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.$services.powerLib.notification.showSnackbar({
                                message : 'Une erreur est survenue lors de la récupération de la fiche intervention',
                                type : 'error'
                    })
                })
            },
            compoundMoPm(){
                return 'plop'
            }
        }
    }
</script>

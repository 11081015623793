export const UPDATE_INTERVENTION = (state,data) => {
    state.interventions = data
  }

export const UPDATE_AUTH = (state, auth) => {
  state.auth = auth
}

export const UPDATE_USER = (state, user) => {
    state.user = user;
}

export const UPDATE_TOKEN = (state, token) => {
    state.auth.accessToken = token
}

export const CLEAR_TOKEN = (state) => {
  state.auth.accessToken = ''
}

export const SIDEBAR = (state, val) => {
    state.sidebar = val
}
export const TAB_COMPONENT = (state, item) => {
    state.tabs = state.tabs.filter(itx => itx.component !== item.component)
    state.tabs.splice(0,0,item)
    state.sidebar = false
}

export const TAB_PRECEDENTE = (state) => {
    state.tabs.splice(0,1)
    state.sidebar = false
}

export const CLEAR_ALL_DATA = (state) => {
    state.auth = {
        isLoggedIn: false,
        accessToken: null,
        refreshToken: null
    }
    state.interventions =   {}
    state.user = {
        name: ''
    }
    state.tabs = [{
        component: 'vx-accueil',
        title: 'Editech',
        dataProvider: null
    }]
    state.sidebar = false
}

import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: function (resolve) {
                require(['./pages/VxLogin.vue'], resolve)
            },
            props: true
        },
        {
            path: '',
            name: 'home',
            component: function (resolve) {
                require(['./pages/VxMain.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '*',
            redirect: '/login'
        }
    ],

})

function guardRoute(to, from, next) {

    const auth = store.getters.getAuth()
    if (!auth.isLoggedIn) {
        next({
            name: 'login'
        })
    } else {
        next()
    }
}


export const getUserInfos = state => () => {
    return state.user
}
export const getTab = state => () => {
    return state.tabs
}
export const getAuth = state => () => {
    return state.auth

}
export const getInterventions = state => (jour) => {
    if(!jour){
        return state.interventions
    } else {
        if(state.interventions[jour] == undefined){
            return state.interventions
        } else {
            return { [jour] : state.interventions[jour]}
        }
    }
}

/**
 * Permet de récupérer l'intervention en cours si elle existe
 *
 * @param state
 * @returns {function(): null}
 */
export const getInterventionEnCours = state => () => {
    let currentIntervention = null;

    Object.entries(state.interventions)[0][1].forEach((inter) => {
            if (inter.inter_date_debut && inter.clotures.length == 0) {
                currentIntervention = {
                    id: inter.inter_id,
                    logement: inter.logement.logement_resident,
                    cp: inter.logement.logement_cp,
                    ville: inter.logement.logement_ville,
                    adresse: inter.logement.logement_adresse,
                    segment_horaire: inter.segment_horaire.segment_horaire_libelle,
                    cr_code: inter.type_cr.cr_code,
                    hierarchie_code: inter.hierarchie_code
                };
            }
    });

    return currentIntervention;
}

export const getSidebarState = state => () => {
    return state.sidebar

}
export const getAccessToken = state => () => {
    return state.auth.accessToken
}
export const getRefreshToken = state => () => {
    return state.auth.refreshToken
}
export const currentTabComponent = state => () => {
    return state.tabs[0].component
}
export const currentTitle = state => () => {
    return state.tabs[0].title
}
export const currentData = state => () => {
    return state.tabs[0].dataProvider
}

<script>
import axios from 'axios'
const format = require('../Format/Json/Json.vue').default
const notification = require('../Power/PowerNotification').default
export default {
    url: null,
    get (path, config = null){
        let self = this;
        return new Promise((resolve, reject) => {
            let myConfig = config ? config : {
                headers: {'Content-Type': 'application/json'},
            };
            let localUrl = ''
            if (path.indexOf('http') === 0) {
                localUrl = path;
            } else {
                localUrl = (self.url) ? (self.url  + '/' +  path) : path;
            }
            axios.get(localUrl, myConfig)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                    reject(response)
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 422) {
                            reject(error.response.data);
                        }
                    }
                    reject(error)
                })
        });


    },
    getJson (path, config = null){
        return new Promise((resolve, reject) => {
            this.get(path, config)
                .then(data => resolve(format.jsonClean(data)))
                .catch(err => reject(err))
        });
    },
    post (path, data = {}, config = null) {
        let self = this;
        return new Promise((resolve, reject) => {
            let myConfig = config ? config : {
                headers: {'Content-Type': 'application/json'},
            };
            let formDatas = new FormData();
            window.$.each(data, function (index, value) {
                if (value) {
                    formDatas.append(index, value);
                } else {
                    formDatas.append(index, '');
                }
            });
            let localUrl = ''
            if (path.indexOf('http') === 0) {
                localUrl = path;
            } else {
                localUrl = (self.url) ? (self.url  + '/' +  path) : path;
            }
            axios.post(localUrl, formDatas, myConfig)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                    reject(response)
                })
                .catch(function (error) {
                    if (error.response && error.response.status === 422) {
                        reject(error.response.data)
                    }
                    reject(error)
                })
        })
    },
    postRaw (path, data = {}, config = null) {
        let self = this;
        return new Promise((resolve, reject) => {
            let myConfig = config ? config : {
                headers: {'Content-Type': 'application/json'},
            };
            let localUrl = ''
            if (path.indexOf('http') === 0) {
                localUrl = path;
            } else {
                localUrl = (self.url) ? (self.url  + '/' +  path) : path;
            }
            axios.post(localUrl, data, myConfig)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                    reject(response)
                })
                .catch(function (error) {
                    if (error.response && error.response.status === 422) {
                        reject(error.response.data)
                    }
                    reject(error)
                })
        })
    },
    postJson (path, data = {}, config = null){
        return new Promise((resolve, reject) => {
            this.post(path, data, config)
                .then(data => resolve(format.jsonClean(data)))
                .catch(err => reject(err))
        });
    },
    put (path, data = {}, config = null) {
        let self = this;
        return new Promise((resolve, reject) => {
            let myConfig = config ? config : {
                headers: {'Content-Type': 'application/json'},
            };
            let formDatas = new FormData();
            window.$.each(data, function (index, value) {
                if (value) {
                    formDatas.append(index, value);
                } else {
                    formDatas.append(index, '');
                }
            });
            formDatas.append('_method', 'put');
            let localUrl = ''
            if (path.indexOf('http') === 0) {
                localUrl = path;
            } else {
                localUrl = (self.url) ? (self.url  + '/' +  path) : path;
            }
            axios.post(localUrl, formDatas, myConfig)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                    reject(response)
                })
                .catch(function (error) {
                    if (error.response && error.response.status === 422) {
                        reject(error.response.data)
                    }
                    reject(error)
                })
        })
    },
    putRaw (path, data = {}, config = null) {
        let self = this;
        return new Promise((resolve, reject) => {
            let myConfig = config ? config : {
                headers: {'Content-Type': 'application/json'},
            };
            let localUrl = ''
            if (path.indexOf('http') === 0) {
                localUrl = path;
            } else {
                localUrl = (self.url) ? (self.url  + '/' +  path) : path;
            }
           axios.put(localUrl, data, myConfig)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                    reject(response)
                })
                .catch(function (error) {
                    if (error.response && error.response.status === 422) {
                        reject(error.response.data)
                    }
                    reject(error)
                })
        })
    },
    putJson (path, data = {}, config = null) {
        return new Promise((resolve, reject) => {
            this.put(path, data, config)
                .then(data => resolve(format.jsonClean(data)))
                .catch(err => reject(err))
        });
    },
    del (path) {
        let localUrl = ''
        if (path.indexOf('http') === 0) {
            localUrl = path;
        } else {
            localUrl = (this.url) ? (this.url  + '/' +  path) : path;
        }
        return axios.delete(localUrl).then((response) => {
            return response.data
        })
    },
    openPdf (path) {
        let config = {
            responseType: 'arraybuffer',
            headers: { 'Content-Type': 'application/json' }
        }
        return new Promise((resolve, reject) => {
            this.get(path, config)
                .then((response) => {
                    let blob = new Blob([response], { type: 'application/pdf' })
                    let url = window.URL.createObjectURL(blob)
                    window.open(url)
                    resolve()
                })
                .catch(err => reject(err))
        })
    },
    openJpgBase64 (data) {
        let base64ImageData = 'data:image/jpeg;base64,' + data
        const byteCharacters = atob(base64ImageData.substr('data:image/jpeg;base64,'.length))
        const byteArrays = []
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024)
            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }
        const blob = new Blob(byteArrays, { type: 'image/jpeg' })
        const blobUrl = URL.createObjectURL(blob)
        window.open(blobUrl, '_blank')
    },
    openUrl (path) {
        return new Promise((resolve, reject) => {
            this.get(path, null)
                .then((response) => {
                    if (response.message) {
                        notification.showSnackbar({
                            message : response.message,
                            type : 'error'
                        })
                    }
                    else {
                        window.open(response.fileUrl, '_blank');
                    }
                    resolve()
                })
                .catch(err => reject(err))
        })
    }
}
</script>
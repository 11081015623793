<template>
    <div>
        <cx-number :dataProvider="dataProvider" @change='emit($event)'></cx-number>
        <!-- indique le message partie métier non factorisé -->
        <div v-if='message==1' class='pa-2 mt-2 '
             style='border: 2px solid black;background-color:green;color:white;font-weight:bold'>
            <v-icon style='color:white'>done</v-icon>
            La situation est normale.
        </div>
        <div v-if='message==2' class='pa-2 mt-2 '
             style='border: 2px solid black;background-color:orange;color:white;font-weight:bold'>
            <v-icon style='color:white'>warning</v-icon>
            Anomalie de fonctionnement -> Investigations complémentaires sur tirage et ventilation
        </div>
        <div v-if='message==3' class='pa-2 mt-2 '
             style='border: 2px solid black;background-color:red;color:white;font-weight:bold'>
            <v-icon style='color:white'>cancel</v-icon>
            Danger grave et imminent -> mise à l'arrêt de la chaudière et recherche de dysfonctionnement
        </div>
    </div>
</template>


<script>

export default {
    /**
     * Composant de surcouche au number nécessaire pour le CO peut être amener à être généraliser
     * en donnant seuil et message dans le dataProvider
     * mais réflexion plus globale à faire sur la flexibilité du nb de seuil et couleur
     */
    name: "cx-mesure-co",
    components: {
        'CxNumber': () => import('./CxNumber')
    },
    props: {
        dataProvider: {
            type: Object
            /*
            {
                libelle: '',
                choices: '',
                data: ''
            }
            */
        }
    },
    data() {
        return {
            value: '',
            message: ''
        }
    },
    mounted() {
        this.value = this.dataProvider.data
    },
    methods: {
        emit(ev) {
            if (!ev) {
                ev = null;
                this.message = '';
                return this.$emit('change', ev);
            }

            /**
             * definir le message à afficher suivant seuil CO / logique métier
             */
            if (ev < 10) {
                this.message = 1
            } else if (ev >= 10 && ev <= 50) {
                this.message = 2
            } else if (ev > 50) {
                this.message = 3
            }

            return this.$emit('change', ev)
        }
    }
}
</script>

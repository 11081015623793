import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Auth from './auth'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'font-awesome/css/font-awesome.min.css' // Ensure you are using css-loader
import services from './services'
import './registerServiceWorker'
import vueSignature from "vue-signature"

if (!window._) {
    window._ = require('lodash')
}
if (!window.$) {
    window.$ = window.jQuery = require('jquery')
}
if (!window.axios) {
    window.axios = require('axios')
}

require('./imports.js')

Vue.use(vueSignature)

// Authentification
Vue.use(Auth, {'client_secret': 'ri18Uo2lfGw4sIVZXNRsXJp4fWJxmQyNOAUzZMXv', 'client_id': 11})
Vue.prototype.$auth = Auth

// Vuetify
Vue.config.productionTip = false
const opts = {};
Vue.use(Vuetify);

// Service
Vue.use(services)
Vue.prototype.$services = services
window.request = Vue.prototype.$services.request

// DEBUG PWA avec son téléphone en environnement de dev
// https://stackoverflow.com/questions/34160509/options-for-testing-service-workers-via-http
// https://developer.chrome.com/docs/devtools/remote-debugging/local-server/
// https://developer.chrome.com/docs/devtools/remote-debugging/#discover
// Permet de désactiver le devtools en production
if (process.env.NODE_ENV === 'production') {
    Vue.config.devtools = false;
}

new Vue({
    render: h => h(App),
    created: function () {
        switch (process.env.NODE_ENV) {
            case 'development':
                this.$services.request.baseUrl = '/baseURL'
                this.$services.request.url = '/baseURL'
                break
            case 'preproduction':
                this.$services.request.baseUrl = '//edison-api-preprod.phinelec.fr'
                this.$services.request.url = '//edison-api-preprod.phinelec.fr'
                break
            case 'production':
                this.$services.request.baseUrl = '//phizz-api.phinelec.fr/'
                this.$services.request.url = '//phizz-api.phinelec.fr/'
                break
            default:
                this.$services.request.baseUrl = '//edison-api-preprod.phinelec.fr'
                this.$services.request.url = '//edison-api-preprod.phinelec.fr'
                break
        }
    },
    vuetify: new Vuetify(opts),
    store,
    router,
}).$mount('#app')

<template>
    <!-- composant utilisé pour afficher un numéro utile en cas d'urgence officiellement arreteà ce jour -->
    <div class="d-flex justify-center">
        <v-card class="flex-grow-1 mx-auto ma-5 ml-4 mr-4" color="white" max-width="700">
            <v-container grid-list-md>
                <v-layout class="flex-column flex-sm-row">
                    <v-flex class='pl-5' xs11>
                        <div style='font-weight:bold'>Numéro d'urgence en cas de problème technique </div>
                        <div> 09.73.45.47.99 </div>
                    </v-flex>
                    <v-flex xs1 align-self-center>
                        <div>
                            <a :href="'tel:'+ '0973454799'">
                                <v-icon medium >phone</v-icon>
                            </a>
                        </div>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>

    </div>

</template>

<script>

    export default {
        name: 'vx-numeros-utiles'
    }
</script>


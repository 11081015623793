export const STORAGE_KEY = 'f963183b4838ebf2d7affb4eed32c5f4mk13'

let initialState = {}

if (localStorage.getItem(STORAGE_KEY)) {
    initialState = JSON.parse(localStorage.getItem(STORAGE_KEY))
} else {
    initialState = {
        auth: {
            isLoggedIn: false,
            accessToken: null,
            refreshToken: null
        },
        interventions: {
        },
        user: {
            name: ''
        },
        sidebar: false,
        tabs:[{
            component: 'vx-accueil',
            title: 'Editech',
            dataProvider: null,
        }]
    }
}

export const state = initialState


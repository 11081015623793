import router from './router.js'
import store from './store'
import services from './services'
//import Vue from 'vue'

export default {
    options: {},
    activation: true,
    countRetry: 0,
    refreshingToken: false,
    install(Vue, options) {
        this.options = options
        window.axios.interceptors.request.use((request) => {
            if (this.activation) {
                
                const token = store.getters.getAccessToken()
                
                if (token) {
                    return this.setAuthHeader(request)
                }
                
                return request
            }
        }, (err) => {
            return err
        })

        window.axios.interceptors.response.use((response) => {
            return response
        }, (err) => {
            let retour = false
            if (this.activation) {
                switch (err.response.status) {
                    case 400:
                        retour = this.logout()
                        break
                    case 401:
                        if (err.response.config.url !== window.request.url + '/oauth/token' && err.response.config.url !== window.request.url + '/oauth/token/refresh') {
                            retour = this._refreshToken(err.response)
                        } else {

                            retour = this.logout()
                        }
                        break
                    default:
                        retour = false
                        break
                }
            }
            return retour
        })
    },
    login(username, password, redirect) {
        const params = {
            'grant_type': 'password',
            'username': username,
            'password': password,
            'client_secret': this.options.client_secret,
            'client_id': this.options.client_id
        }
        this.activation = true
        this.countRetry = 0
        return window.axios.post(services.request.baseUrl + '/oauth/token', params)
            .then((response) => {
                this._storeToken(response)
                if (redirect) {
                    router.push({name: redirect})
                }
                return response
            })
    },
    getUserTechnicienInformation(){
        
        return window.axios.get(services.request.baseUrl + '/api/pwa/general/user')
            .then((response) => {
                this.storeUser(response.data)
            })
    },
    storeUser(user) {
        store.commit('UPDATE_USER', user)
    },
    logout() {
        if (this.activation) {
            this.activation = false
        }
        store.commit('CLEAR_ALL_DATA')
        if (router.currentRoute.name !== 'login') {
            router.push({name: 'login'})
        }
    },
    setAuthHeader(request) {
        request.headers['Authorization'] = 'Bearer ' + store.getters.getAccessToken()
        return request
    },
    _retry(request) {
        return window.axios(this.setAuthHeader(request.config))
            .then((response) => {
                return response
            })
            .catch((response) => {
                return response
            })
    },
    _refreshToken(request) {
        const params = {
            'grant_type': 'refresh_token',
            'refresh_token': store.getters.getRefreshToken() || 'null',
            'client_secret': this.options.client_secret,
            'client_id': this.options.client_id
        }
        if (request.config.headers.Authorization === 'Bearer ' + store.getters.getAccessToken() && !this.refreshingToken) {
            this.refreshingToken = true
            return window.axios.post(services.request.baseUrl + '/oauth/token/refresh', params)
                .then((result) => {
                    if (this.activation) {
                        if (result !== undefined) {
                            if (result.status === 200) {
                                this._storeToken(result)
                                this.refreshingToken = false
                                this.countRetry = 0
                                return this._retry(request)
                            } else {
                                return this.logout()
                            }
                        }
                    }
                })
                .catch(() => {
                    this.logout()
                })
        } else if (this.activation && this.countRetry === 0) {
            this.countRetry += 1
            return this._retry(request)
        } else {
            return this.logout()
        }
    },
    _storeToken(response) {
        const auth = store.getters.getAuth()
        auth.isLoggedIn = true
        auth.accessToken = response.data.access_token
        auth.refreshToken = response.data.refresh_token
        store.commit('UPDATE_AUTH', auth)

    },
    _isInvalidToken(response) {
        const status = response.status
        const error = response.data.error
        return (status === 401 && error === 'Unauthenticated.')
    },

}

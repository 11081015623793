<template>
   <div class="d-flex flex-column">
    
            <div class="d-flex justify-space-between pl-3 font-weight-light pt-2 pb-2 subheading white--text bg-success" style="background:#25207a;">
                <div class="align-self-center">
                    Bâtiment du jour - {{(getBandeauDateInterFormat(Date.now()))}}
                    <v-icon class="ml-6 align-self-center" color="white" style='font-size:40px' @click="getDatas">refresh</v-icon>
                </div>
            </div>

            <div v-if="!loading" v-for="(batiment, index) in datas" :key="index" color="#f0f5fb" elevation="0" tile>
                <v-card
                        tile
                        color="#E0E0E0"
                        class="mx-auto"
                    >
                        <div style="background:grey;height:30px;text-align:center" class="pl-3 font-weight-light pt-1 pb-2 subheading white--text bg-success">
                            {{batiment.hierarchie_code}}
                        </div>
                        <v-container  class="pt-0 pb-0" grid-list-md>
                            <v-layout row wrap class="pt-0 mt-2 pr-3 justify-space-between">
                                <v-flex class="pt-2" xs10>
                                    <div class='mb-3' ><strong>Client</strong> : {{ batiment.client.client_nom }} </div>

                                    <div class="subheading" style='font-weight:bold;' v-bind:style="{ 'color': 'black'  }">
                                        <div> {{batiment.adresse_hierarchie.adresse_libelle}} </div>
                                    </div>
                                    <div> {{batiment.adresse_hierarchie.adresse_adresse1}} </div>
                                    <div> {{batiment.adresse_hierarchie.adresse_adresse2}} </div>
                                    <div> {{batiment.adresse_hierarchie.adresse_cp}} {{batiment.adresse_hierarchie.adresse_ville}}</div>
                                   
                                </v-flex>
                                <v-flex class="pt-0" style='text-align:right;pading-right:10px;' xs2>
                                    <!-- <v-icon v-if='intervention.intervention_doc_autre' color="grey" style='font-size:40px' @click="openComponent('vx-inter-document',intervention)">folder</v-icon> -->
                                    <div >
                                        <v-bottom-sheet inset >
                                            <template v-slot:activator="{ on }">
                                                <v-btn small icon>
                                                    <v-icon  class='pt-10 pr-2' style='font-size:50px' large color="#fd7e14" v-on="on">list</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item-title style=" margin:5px; color: #EF6C00; text-align:center;" class="subheading">CHOISISSEZ UNE ACTION</v-list-item-title>
                                                <v-divider color="#EF6C00"></v-divider>
                                                <v-list-item  @click="openComponent('vx-fiche-infos-utiles-batiment', batiment)">
                                                    <v-list-item-title style="color: #616161; text-align:center;"> Accéder aux Infos Utiles </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="openComponent('vx-fiche-risques-batiment', batiment)">
                                                    <v-list-item-title style="color: #616161; text-align:center;"> Accéder aux Risques Identifiés </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-bottom-sheet>
                                        <div class='mb-3'>
                                            <br>
                                            <div class="pl-l pt-5" >
                                                <!-- <v-icon  v-if="intervention.clotures.length > 0" color="green" style='font-size:40px'>check_circle_outline</v-icon>
                                                <v-icon  v-else-if="intervention.inter_date_debut && intervention.clotures.length == 0" color="lightorange" style='font-size:40px'>pending</v-icon>
                                                <v-icon  v-else color="grey" style='font-size:40px'>schedule</v-icon> -->
                                            </div>
                                        </div>
                                    </div>
                                </v-flex>
                            </v-layout>
                            <!-- <div v-if='intervention.precision_horaires.length > 0'>
                                <hr>
                                <div class='pb-2 pt-2' style='text-align:left;'> {{intervention.precision_horaires[0].commentaire_texte}} </div>
                            </div> -->
                        </v-container>
                    </v-card>
                </div>
    
        <div v-if="loading">
            <div v-for="id of [1, 2, 3, 4]" v-bind:key="id">
                <v-skeleton-loader type="card">
                </v-skeleton-loader>
            </div>
        </div>
    </div>
</template>
<script>
export default {
        name: 'vx-mes-batiments',
        data() {
           return {
            datas : {},
            loading: false,
           }
        },

        mounted() {
            this.getDatas()
        },

        methods: {
            getBandeauDateInterFormat(date){
                return this.$services.format.moment.getDate(date,'DD / MM / YYYY')
            },
            openComponent(component, batiment) {
                this.$store.commit('TAB_COMPONENT', {
                    title: 'Bâtiment ' + batiment.hierarchie_code,
                    component: component,
                    dataProvider: { batiment: batiment }
                });
            },
            getDatas() {
            this.loading = true
            this.$services.request.get('api/pwa/mes-batiments/batiments')
                .then((response) => {
                    this.loading = false
                    this.datas = response;
                }).catch(() => {
                    this.$services.powerLib.notification.showSnackbar({
                        message: 'Une erreur est survenue lors de la récupération des bâtiments',
                        type: 'error'
                    })
                    this.loading = false
                })
            }
        }
    }


    
   
</script>

<style lang="scss">
    // DOC : Enlever le ripple effect sur les button
    //https://stackoverflow.com/questions/60756963/remove-ripple-effect-on-icon-button
    .v-btn {
        &:before {
            opacity: 0 !important;
        }
    }

    .v-ripple__container {
        opacity: 0 !important;
    }

    .v-icon {
        &:after {
            opacity: 0 !important;
        }
    }

<template>
    <div>

        <div v-if='displayAdd'>
            
                <cx-button label='RETOUR' @click='displayAdd=false'></cx-button>
                <v-list-item v-for="(item) in dataProvider.data.appareils[this.currentAppareil][this.typeAdd+'_list']" :key="item.id">
                    <v-list-item-content style='height:55px'>
                        <v-checkbox  v-if="typeAdd=='mo'" :value="item.id" 
                                    :key="item.id"
                                    :label="item.pivot.mo_code + ' - ' + item.libelle"
                                    v-model="item.checked"
                                    >
                        </v-checkbox>
                        <v-checkbox v-if="typeAdd=='pm'" :value="item.id" 
                                    :key="item.id"
                                    :label="item.piece_code + ' - ' + item.piece_libelle"
                                    v-model="item.checked"
                                    >
                        </v-checkbox>
                    </v-list-item-content> 
                </v-list-item>
                <cx-button label='Ajouter la liste selectionnées' @click='add()'></cx-button>
        </div>
        
        <div v-if='!displayAdd'>
            <v-container class='pt-6 px-6'>
            <div v-for="(appareil,index) in dataProvider.data.appareils" :key="index">
                <v-col style='overflow-wrap:normal;'>
                    <v-row class='mb-5'> 
                        <div class='font-weight-bold mr-2'>APP :</div>  
                        <span>
                            {{appareil.produit.produit_code}} - {{appareil.produit.produit_libelle}}
                            <div>{{appareil.appareil_data.piece}} </div>
                        </span>  
                   </v-row>
                    
                    <v-row><div class='font-weight-bold mr-2'>MO :</div>  
                        <v-card @click='deleteItem(index,mo.id,"mo")' v-for="(mo) in dataProvider.data.appareils[index].mo" :key="mo.id" class='pa-1 ma-1'>
                            <span>{{mo.pivot.mo_code}}</span><v-icon size="25" color='red' style='margin-top:-3px'>clear</v-icon>
                        </v-card>
                    </v-row>
                     
                    <v-row><div class='font-weight-bold mr-2'>PM :</div>  
                        <v-card @click='deleteItem(index,pm.id,"pm")' v-for="(pm) in dataProvider.data.appareils[index].pm" :key="pm.id" class='pa-1 ma-1'>
                            <span>{{pm.piece_code}}</span><v-icon size="25" color='red'  style='margin-top:-3px'>clear</v-icon>
                        </v-card>
                    </v-row>

                </v-col>
                <v-container>
                <v-divider class='mb-2' ></v-divider> 
                <div class='d-flex justify-space-around'>
                    <cx-button class='pa-1' label='AJOUTER MO' @click='display(index,"mo")'></cx-button>
                    <cx-button class='pa-1' label='AJOUTER PM' @click='display(index,"pm")'></cx-button>
                </div>
            </v-container>
                <v-divider class='mb-2' style='border: 1px solid black' ></v-divider> 
            </div>
            </v-container>  
        </div> 
    </div>
</template>


<script>

  export default {
        name: "cx-selection-mopm",
        components: {
            'CxButton': () => import('../../components/action/CxButton')
        },
        props: {
            intervention: {
                type: Number
            },
            dataProvider: {
                type: Object
                 /*
                data: {
                    appareils : [
                        {
                            appareil_id: 502726,
                            pm_list:[liste à afficher dans l'ajout]
                            mo_list:[]
                            pm:[liste comprenant les items ajoutés, utilisé dans la vue principal],
                            mo:[]
                        }
                    ]
                } 
                */
            }
        },
        data() {
            return {
                datas : {},
                currentAppareil: 0,
                displayAdd: false,
                typeAdd: ''
            }
        },
        watch: {
            displayAdd: function(val){
                this.$emit('toggleBtn',!val)
            }
        },
        methods: {
            display(index,type){    
                this.currentAppareil = index
                this.resetSelectedItem(index,type)
                this.typeAdd = type
                this.displayAdd = true
            },
            resetSelectedItem(index,type){
                //le back ne renvoie pas les checked dans la liste on les injecte pour 
                //que la liste soit précoché
                if(type in this.dataProvider.data.appareils[index]){ //le back peut ne pas renvoyer de tableau si vide

                    //on sort un tableau des id existants
                    let idArray = this.dataProvider.data.appareils[index][type].map((el)=>{
                        return el.id
                    })

                    //on rajoute le checked sur la liste de configuration
                    this.dataProvider.data.appareils[index][type+'_list'].forEach((el)=>{
                        if(idArray.indexOf(el.id) != -1){
                            el.checked = el.id
                        }
                    })
                } 
            },
            add(){
                this.displayAdd = false
                this.dataProvider.data.appareils[this.currentAppareil][this.typeAdd] = []
                this.dataProvider.data.appareils[this.currentAppareil][this.typeAdd +'_list'].map((item)=>{
                    if(item.checked){ 
                        this.dataProvider.data.appareils[this.currentAppareil][this.typeAdd].push(item) 
                    }
                })
            },
            deleteItem(index,id,type){

                this.dataProvider.data.appareils[index][type].forEach((item,indexForEach)=>{
                    
                    if(item.id == id){
                        this.dataProvider.data.appareils[index][type].splice(indexForEach,1)
                        this.dataProvider.data.appareils[this.currentAppareil][type +'_list'].map((itemList)=>{
                            if(itemList.id == id){ 
                                delete itemList.checked 
                            }
                        })
                    }
                })
                this.$forceUpdate(); //nécessaire car sinon la liste n'est pas mis àjour
            }
        }
    }
</script>

import removeAccents from '../removeAccents/removeAccents'

//filter array or object by one character
function filterBy (filter, target) {

    let newArray = []
    filter = removeAccents(filter)

    // search in array
    if (target && Array.isArray(target) && target.length > 0){
        let cols = Object.keys(target[0])
        
        //check each value
        for (let i = 0; i < target.length; i++) {
            
            let check = 0

            //check each character
            for (let j = 0; j < cols.length; j++) {
                
                let val = target[i][cols[j]]

                // array or object again
                if ((val && Array.isArray(val)) || (typeof val === 'object')) {
                    // character match keep this value
                    if (filterBy(filter, val).length > 0) {
                        check++
                        break
                    }
                }
                // not object
                else if (!(typeof val === 'object') && val) {
                    
                    // not array compare string
                    if (!Array.isArray(val)) {

                        val = removeAccents(val)
                        if (val.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                            check++
                            break
                        }

                    } else {
                        if (filterBy(filter, val)) {
                            check++
                            break
                        }
                    }
                }
            }
            (check > 0) ? newArray.push(target[i]) : ''
        }
    } 
    // search in object
    else if (target && typeof target === 'object') {

        let cols = Object.keys(target)
        let check = 0
        for (let i = 0; i < cols.length; i++) {
            let val = target[cols[i]]
            if ((val && Array.isArray(val)) || (typeof val === 'object')) {
                if (filterBy(filter, val).length > 0) {
                    check++
                    break
                }
            } else if (!(typeof val === 'object') && val) {
                if (!Array.isArray(val)) {
                    val = removeAccents(val)
                    if (val.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                        check++
                        break
                    }
                } else {
                    if (filterBy(filter, val)) {
                        check++
                        break
                    }
                }
            }
        }
        if (check > 0) {
            newArray.push(target)
        }
    }

    return newArray
}

export default filterBy

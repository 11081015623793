<template>
    <div class="text-center">
        <!-- on upload une seule photo -->
        <div  @click="openInputFile" style='width:90%;height:40px;background-color:lightgrey;border:3px dashed grey' class='mx-auto'>
            <div v-if="!uploaded" class='d-flex' style="justify-content:center">
                <div v-if='!loading'><v-icon size="30" color='white'>add_photo_alternate</v-icon></div>
                <div v-else><v-progress-circular indeterminate color="orange"></v-progress-circular></div>

                <div  class="ml-2 mt-1">Ajouter une photo</div>
            </div>
            <div v-else class='d-flex' style="justify-content:center">
                <v-icon size="30" color='green'>check</v-icon>
                <div  class="ml-2 mt-1">Photo uploadée</div>
            </div>
        </div>

        <input ref="file" type="file" @change="fileUpload" hidden>

    </div>
</template>

<script>
    export default {
        name: "CxUploadPhoto", 
        data () {
            return {
                uploaded : false,
                loading: false
            }
        },
        props: {
            model: {
                type: String,
                default: ''
            },
            id: {
                type: Number,
                default: 0
            },
            type: {
                type: String,
                default: ''
            },
            //ce props permet d'utiliser le composant dans le cadre du scénarii également
            dataProvider: {
                type: Object
                /*
                {
                    choices: ''
                }
                */
            },
            intervention: {
                type: Number
            }
        },
        methods: {
            openInputFile () {
                this.$refs.file.click()
            },
            fileUpload () {
                let data = {
                            id : this.getId(),
                            model: this.getModel(),
                            files: event.target.files[0],
                            type: this.getId(),
                            filename: this.getNomenclaturePictureName()
                        }
                this.loading = true
                this.$services.request.post('api/pwa/mes-interventions/file',data)
                    .then( () => {
                        this.$services.powerLib.notification.showSnackbar({
                                    message : 'La photo a bien été uploadée',
                                    type : 'success'
                        })
                        this.uploaded = true
                        this.loading = false
                        this.$emit("upload");
                        this.$emit('change', this.getNomenclaturePictureName());
                    })
                    .catch(()=>{
                        this.$services.powerLib.notification.showSnackbar({
                                    message : 'Une erreur est survenue lors du téléchargement',
                                    type : 'error'
                        })
                        this.loading = false
                    })
            },
            getNomenclaturePictureName(){

                return this.getId() + '_' + Date.now() + '_' + this.getType() +'.png'
            },
            getId() {
                return (!this.id && this.intervention) ? this.intervention : this.id;
            },
            getType() {
                return (this.type === '' && this.dataProvider.choices.length > 0) ? this.dataProvider.choices[0].value : this.type;
            },
            getModel() {
                return (this.model) ? this.model : 'interventions';
            }
        }
    }
</script>
<template>
    <div v-if="etapesProvider">

        <v-toolbar-title class='global-main-title mb-5' >{{etapesProvider.etapes[indexCurrentStep].titre}}</v-toolbar-title>
        <div class='mx-5'>
            <component class='mb-5' v-for="(champ, index) in etapesProvider.etapes[indexCurrentStep].champs" v-bind:key="(champ.identifiant_CRI) ? champ.identifiant_CRI : champ.id" v-bind:is="champ.nom_composant" v-model='champ.data' @change='update(index,$event)' :dataProvider='champ' :intervention='dataProvider.intervention' @toggleBtn="toggleBtn"/>
            <div v-if='loading' style="display:flex;justify-content:center;align-items:center;"><v-progress-circular indeterminate color="orange"></v-progress-circular></div>
            <div v-if='!loading && displayBtn' class='d-flex justify-space-around'>
                    <cx-button label='PRECEDENT' @click='previous'></cx-button>
                    <cx-button v-if='indexCurrentStep+1 < etapesProvider.etapes.length' label='SUIVANT' @click='next'>
                    </cx-button>
                    <cx-button v-if='indexCurrentStep+1 == etapesProvider.etapes.length' label='CLOTURER' @click='cloturer'></cx-button>
            </div>
        </div>
    </div>
</template>
<script>

    
    export default {
        name: 'vx-scenarii',
        props : {
            dataProvider: {}
        },
        components: {
            'CxButton': () => import('../../components/action/CxButton')
        },
        data() {
            return {
                requiredFilled: true,
                fetchDataProvider: true,
                indexCurrentStep : 0,
                etapesProvider: null,
                loading: false,
                displayBtn: true
            }
        },
        mounted(){
            //affiche la derniere etape sauvegardé en back
            this.fetchEtapesProvider(true)
        },
        methods: {
            update(champs,value){
                this.etapesProvider.etapes[this.indexCurrentStep].champs[champs].data = value
            },
            async fetchEtapesProvider(refresh = false, previous = false){
                 
                this.$services.request.get('api/pwa/sequences/interventions/' + this.dataProvider.intervention )
                .then((response)=>{
                    this.etapesProvider = response
                    if(refresh){
                        if(previous){
                            this.indexCurrentStep = this.getIndexStepByEtapeId(this.etapesProvider.previous)
                        } else {
                            (!this.etapesProvider.currentStep) ? this.indexCurrentStep = 0 : this.indexCurrentStep = this.getIndexStepByEtapeId(this.etapesProvider.currentStep)
                        }
                    } else {
                        this.getNextStep()
                    }

                     this.loading = false // stop le loading lancé par le submit() 
                })
                .catch( () => {
                    this.$services.powerLib.notification.showSnackbar({
                        message : 'Une erreur est survenue lors du chargement du scénario',
                        type : 'error'
                    })
                })

            },
            getIndexStepByEtapeId(id){
               
                let result =''
                Object.keys(this.etapesProvider.etapes).forEach((index)=>{
                    if(this.etapesProvider.etapes[index].id == id){
                        result =  parseInt(index) //on affiche l'étape après la dernière enregistrée
                    }
                })
                return result
                
            },
            getNextStep(){
                //au moment du chargement si c'est null c'est la première étape
                if(!this.etapesProvider.currentStep){ 
                    this.indexCurrentStep = 0
                     return;
                }
                
                
                //en prio si l'étape contient un next steps 
                //on pars sur l'étape cible
                if(this.etapesProvider.etapes[this.getIndexStepByEtapeId(this.etapesProvider.currentStep)].next_sequence_etape_id){ 
                    this.indexCurrentStep = this.getIndexStepByEtapeId(this.etapesProvider.etapes[this.indexCurrentStep].next_sequence_etape_id)
                    return;
                }

                let stop = false;
                //deuzio le premier element renseigné contenant un next step
                this.etapesProvider.etapes[this.getIndexStepByEtapeId(this.etapesProvider.currentStep)].champs.forEach((champ)=>{
 
                    if(champ.data && champ.choices){
                        champ.choices.forEach((choice)=>{      
                            
                            if(choice.value == champ.data && Object.keys(choice).indexOf("next_sequence_etape_id") != -1 && choice.next_sequence_etape_id){
                                this.indexCurrentStep = this.getIndexStepByEtapeId(choice.next_sequence_etape_id)       
                                stop = true;
                            }
                        })
                    }
                })
                if(stop){return;}

                //le suivant dans la liste si on n'est tjr au step actuel
                this.indexCurrentStep = this.getIndexStepByEtapeId(this.etapesProvider.currentStep)
                this.indexCurrentStep++
            },
            async next(){ 
                await this.submit(true)
                if (this.fetchDataProvider) {
                    await this.fetchEtapesProvider();
                }
            },
            async previous(){
               
               await this.submit()
               await this.fetchEtapesProvider(true,true)

                    //on ne recupere pas le provider car sinon on passe à l'étape suivante
                    //si on est à la dernière etape de cloture on enregistre pas au précédent
                    //car cela cloturerais l'inter
                
                    let goPrevious = (!(this.indexCurrentStep == (Object.keys(this.etapesProvider.etapes).length-1))) ? this.submit() : true;

                    if(goPrevious){
                        if(this.indexCurrentStep == 0){
                                this.$store.commit('TAB_COMPONENT', {   component: 'vx-gestionnaire-photo', 
                                    title: 'Intervention ' + this.dataProvider.intervention,
                                    dataProvider: { "intervention" : this.dataProvider.intervention }
                                });
                        }
                    
                       
                    }

            },
            async submit(next = false){
                
                this.loading = true // le loading sera stoppé dans fetchEtapesProvider ou cloture

                let requiredChamp = [];
                if (next) {
                    this.etapesProvider.etapes[this.indexCurrentStep].champs.map( (item) => {
                        if (item.required && item.data === null) {
                            this.requiredFilled = false;
                            requiredChamp.push(item.libelle);
                        }
                    });
                }

                if(this.requiredFilled){
                    this.fetchDataProvider = true;

                    let data = this.etapesProvider;
                    this.etapesProvider.etape = this.etapesProvider.etapes[this.indexCurrentStep];
                    
                    await this.$services.request.postRaw('api/pwa/sequences/interventions/' + this.dataProvider.intervention , data)
                        .catch(()=>{
                            this.$services.powerLib.notification.showSnackbar({
                                message : 'Une erreur technique est survenue ',
                                type : 'error'
                            })
                        })
                    

                } else {
                    this.fetchDataProvider = false;

                    this.requiredFilled = true
                    this.$services.powerLib.notification.showSnackbar({
                                message : ((requiredChamp.length > 1) ?'Les champs ' : 'Le champ ') + requiredChamp.join(' et ') + ((requiredChamp.length > 1) ?' sonts ' : ' est') + ' requis.',
                                type : 'error'
                    })
                    this.loading = false
                    return 'no cloture' //empeche le lancement de la cloture
                }
                

                 await this.$services.request.put('api/pwa/sequences/interventions/' + this.dataProvider.intervention + '/reset-avancement-order').catch(()=>{
                            this.$services.powerLib.notification.showSnackbar({
                                message : 'Une erreur technique est survenue ',
                                type : 'error'
                            })
                    })

            },
            cloturer(){
                this.submit(true)
                    .then((response)=>{
                        if(response != 'no cloture'){
                            this.$services.request.post('api/pwa/mes-interventions/intervention/' + this.dataProvider.intervention + '/cloture')
                            .then((response)=>{
                                if(response){
                                    this.$services.powerLib.notification.showSnackbar({
                                        message : 'L\'intervention a été correctement clôturée.',
                                        type : 'success'
                                    })
                                    setTimeout(
                                        this.$store.commit('TAB_COMPONENT', {   component: 'vx-interventions',
                                        title: 'Mon Activité'
                                        }).then(()=>{
                                            this.loading = false // stop le loading lancé par le submit()
                                        })

                                    ,1000);

                                } else {
                                    this.$services.powerLib.notification.showSnackbar({
                                        message : 'Une erreur est survenue lors de la clôture.',
                                        type : 'error'
                                    })
                                }
                            })
                        }
                    })
                
            },
            toggleBtn(val){
                this.displayBtn = val
            }
        }
    }
</script>

<template>
    <div class="ma-3 mt-5">
        <v-card tile elevation="10" class="mx-auto mb-2" color="#a5a5a5" dark max-width="500" style='padding:10px;'>
            <div v-if='currentIntervention'>
                <v-toolbar-title style='text-align:center;' class='pb-2 display-1'>Intervention en cours
                </v-toolbar-title>
                <hr>
                <v-container grid-list-md>
                    <v-layout row wrap style='text-align:center;'>
                        <v-flex>
                            <div style='text-align:center;font-size:18px;' class='mt-2 mb-5'>
                                {{ currentIntervention.hierarchie_code }}
                            </div>
                            <div style='text-align:left;'>
                                <div>{{ currentIntervention.logement }}</div>
                                <div>{{ currentIntervention.adresse }}</div>
                                <div>{{ currentIntervention.cp }} {{ currentIntervention.ville }}</div>
                                <div>{{ currentIntervention.segment_horaire }}</div>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-card-actions class="justify-center">
                    <v-btn block color='#8a8a8a' style='font-size:12px' @click='goToCurrentInterventions()'>
                      Reprendre l'intervention
                      <v-icon class="pl-2">keyboard_arrow_right</v-icon>
                    </v-btn>
                </v-card-actions>
                <!-- <v-container grid-list-md v-if="data.intervention === null">
                    <v-layout row wrap>
                        <v-flex xs12>
                            <div>Pas d'interventions en cours</div>
                        </v-flex>
                    </v-layout>
                </v-container> -->

            </div>
            <v-toolbar-title v-else style='text-align:center;font-size:24px !important;' class='pb-2 display-1'>Aucune
                intervention en cours
            </v-toolbar-title>
            <v-divider></v-divider>
            <v-card-actions class="justify-center mt-5">
                <v-btn block color='#8a8a8a' @click='goToInterventions()'>Accès aux interventions
                    <v-icon class="pl-2">keyboard_arrow_right</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>

        <!-- <v-card elevation="10" tile class="mx-auto mb-2 mt-6" color="#25207a" dark max-width="500" style='padding:10px;'>
            <v-toolbar-title style='text-align:center;' class='display-2 pb-2'>Activités</v-toolbar-title>
            <v-container grid-list-md text-xs-center>
                <v-layout row wrap style='text-align:center;'>
                    <v-flex>
                        <div class="text-xs-center display-3 b-3" style='font-weight:bold'>5</div>
                        <div class="text-xs-center caption pt-1">
                            <div class="text-xs-center display-1 b-1 ">Pièces</div>
                            <div class="text-xs-center display-0 b-0 ">(à récuperer en agence)</div>
                        </div>
                    </v-flex>
                    <v-flex>
                        <div class="text-xs-center display-3 pb-3" style='font-weight:bold'>1/12</div>
                        <div class="text-xs-center display-1 b-1 ">Intervention(s)</div>
                        <div class="text-xs-center display-1 b-1 ">effectuée(s)</div>
                    </v-flex>
                </v-layout>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn block color='#181550'>Accès aux pièces
                    <v-icon class="pl-2">keyboard_arrow_right</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card> -->
    </div>
</template>
<script>
export default {
    name: 'vx-accueil',
    data() {
        return {
            currentIntervention: null,
            interventions: null,
        }
    },
    mounted() {
        this.getInterventions();
    },
    methods: {
        goToInterventions() {
            this.$store.commit('TAB_COMPONENT', {component: 'vx-interventions', title: 'Mes Interventions'});
        },
        openComponent(component, intervention) {
            this.$store.commit('TAB_COMPONENT', {
                title: 'Intervention ' + intervention,
                component: component,
                dataProvider: {intervention}
            });
        },
        goToCurrentInterventions() {
            this.openComponent('vx-logements', this.currentIntervention.id)
        },
        getInterventions() {
            this.$services.request.get('api/pwa/mes-interventions/intervention').then((data) => {
                this.$store.commit('UPDATE_INTERVENTION', data);
                this.interventions = this.$store.getters.getInterventions();
                this.checkDataCurrentInter();
            })
        },
        checkDataCurrentInter() {
            this.currentIntervention = this.$store.getters.getInterventionEnCours();
        }
    }
}
</script>

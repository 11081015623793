<script>

    /**
     * Ce composant à pour but de centraliser toutes les regex de l'application
     * Utilisation :
     * Appel : this.$services.powerLib.regex.getRegex('nom_de_la_regex')
     * 1er argument : Nom de la regex que l'on veut récupérer
     */
    export default {

            regex: {
                email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                mdp: /^(?=.*[!@#$&*])(?=.*[0-9]).{12,}$/,
                phone: /[0-9]{10}$/
            },

            /**
             * Récupére un regex et le retourne
             *
             * @param key
             * @returns {RegExp|*}
             */
            getRegex(key) {
                let data = this.regex[key];

                if (data) {
                    return data;
                }
                else {
                    //console.error('Regex "' + key + '" inexistante')
                    return /^.$/;
                }
            },

        }
</script>
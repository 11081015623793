const format = require('./Format').default
const request = require('./Request/Request.vue').default
const filters = require('./Filters').default
const powerLib = require('./Power/index').default

export default {
    filters: filters,
    request: request,
    format: format,
    powerLib : powerLib
}
import Vue from 'vue'


//MENU
import VxUpdatePassword from './views/Configuration/VxUpdatePassword'

//ACCUEIL
import VxAccueil from './views/Accueil/VxAccueil'

//MES INTERVENTIONS
import VxInterventions from './views/MesInterventions/VxInterventions'

//TRONC COMMUN SCENARII
import VxLogements from './views/Scenarii/VxLogements'
import VxRecapitulatifOperations from './views/Scenarii/VxRecapitulatifOperations'
import VxHistoriqueTechnique from './views/Scenarii/VxHistoriqueTechnique'
import VxInterventionImpossible from './views/Scenarii/VxInterventionImpossible'
import VxGestionnairePhoto from './views/Scenarii/VxGestionnairePhoto'
import VxScenarii from './views/Scenarii/VxScenarii'
import VxInterDocument from './views/Scenarii/VxInterDocument'
import VxFicheInfosUtilesIntervention from './views/Scenarii/VxFicheInfosUtilesIntervention'
import VxFicheRisquesIntervention from './views/Scenarii/VxFicheRisquesIntervention'

//MES DOCUMENTS
import VxMesDocuments from './views/MesDocuments/VxMesDocuments'

//NUMEROS UTILES
import VxNumerosUtiles from './views/MesDocuments/NumerosUtiles/VxNumerosUtiles'

//MES BATIMENTS
import VxMesBatiments from './views/MesBatiments/VxMesBatiments'
import VxFicheInfosUtilesBatiment from './views/MesBatiments/VxFicheInfosUtilesBatiment'
import VxFicheRisquesBatiment from './views/MesBatiments/VxFicheRisquesBatiment'

//CHAMPS SCENARII
import CxMesureCo from './components/scenarii/CxMesureCo'
import CxRadio from './components/scenarii/CxRadio'
import CxNumber from './components/scenarii/CxNumber'
import CxSignature from './components/scenarii/CxSignature'
import CxSelectionMopm from './components/scenarii/CxSelectionMopm'
import CxSelect from './components/scenarii/CxSelect'
import CxTextArea from './components/scenarii/CxTextArea'
import CxInformation from './components/scenarii/CxInformation'
import CxUploadPhoto from './components/action/CxUploadPhoto'




const Imports = [
    //MENU
    {name: 'vx-update-password', component: VxUpdatePassword},

    //ACCUEIL
    {name: 'vx-accueil', component: VxAccueil},

    //MES INTERVENTIONS
    {name: 'vx-interventions', component: VxInterventions},

    //TRONC COMMUN SCENARII
    {name: 'vx-logements', component: VxLogements},
    {name: 'vx-recapitulatif-operations', component: VxRecapitulatifOperations},
    {name: 'vx-historique-technique', component: VxHistoriqueTechnique},
    {name: 'vx-intervention-impossible', component: VxInterventionImpossible},
    {name: 'vx-gestionnaire-photo', component: VxGestionnairePhoto},
    {name: 'vx-scenarii', component: VxScenarii},
    {name: 'vx-inter-document', component: VxInterDocument},
    {name: 'vx-fiche-infos-utiles-intervention', component: VxFicheInfosUtilesIntervention},
    {name: 'vx-fiche-risques-intervention', component: VxFicheRisquesIntervention},

    //MES DOCUMENTS
    {name: 'vx-documents', component: VxMesDocuments},

    //Numéros utiles
    {name: 'vx-numeros-utiles', component: VxNumerosUtiles},

    //MES BATIMENTS
    {name: 'vx-mes-batiments', component: VxMesBatiments},
    {name: 'vx-fiche-infos-utiles-batiment', component: VxFicheInfosUtilesBatiment},
    {name: 'vx-fiche-risques-batiment', component: VxFicheRisquesBatiment},

    //CHAMPS SCENARII
    {name: 'cx-mesure-co', component: CxMesureCo},
    {name: 'cx-radio', component: CxRadio},
    {name: 'cx-number', component: CxNumber},
    {name: 'cx-signature', component: CxSignature},
    {name: 'cx-selection-mopm', component: CxSelectionMopm},
    {name: 'cx-select', component: CxSelect},
    {name: 'cx-text-area', component: CxTextArea},
    {name: 'cx-information', component: CxInformation},
    {name: 'cx-upload-photo', component: CxUploadPhoto}
]


Imports.forEach((Import) => {
    Vue.component(Import.name, Import.component)
})

export default Imports

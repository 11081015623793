<template>
<div v-if="datas">
    <v-toolbar-title class='global-main-title' >Opérations techniques</v-toolbar-title>
    <v-container class='pt-6 px-6'>
        <v-col style='overflow-wrap:normal;'>
            <v-row style='border:2px solid grey;' class='pa-2 mb-2' ><div class='font-weight-bold mr-2'>REMARQUES : </div>{{ datas.precision_horaires.length ? datas.precision_horaires[0].commentaire_texte : ''}} </v-row>
        </v-col> 
        <v-divider class='mb-2' ></v-divider> 
        <div v-for="(appareil,index) in datas.appareils" :key="index">
            <v-col style='overflow-wrap:normal;'>
                <v-row>
                    <div class='font-weight-bold mr-2'>APP :</div>
                    <span>
                    {{appareil.produit.produit_code}} - {{appareil.produit.produit_libelle}}
                        <div>{{appareil.appareil_data.length && appareil.appareil_data.hasOwnProperty(1) ? appareil.appareil_data[1].appareil_data_valeur : 'N/R'}} </div>
                        <div>{{appareil.appareil_data.length && appareil.appareil_data.hasOwnProperty(2) ? appareil.appareil_data[2].appareil_data_valeur : 'N/R'}} </div>
                        <div>{{appareil.appareil_data.length && appareil.appareil_data.hasOwnProperty(4) ? appareil.appareil_data[4].appareil_data_valeur : 'N/R'}} </div>
                        <div>{{appareil.appareil_data.length && appareil.appareil_data.hasOwnProperty(5) ? appareil.appareil_data[5].appareil_data_valeur : 'N/R'}} </div>
                        <div>{{appareil.appareil_data.length && appareil.appareil_data.hasOwnProperty(7) ? appareil.appareil_data[7].appareil_data_valeur : 'N/R'}} </div>
                    </span>
                </v-row>
                <v-row style='background-color:grey' class='pa-2 mb-2 mt-5 white--text'>
                    <div class='font-weight-bold mr-2'>PANNE : {{datas.codes_panne[index].code_panne_libelle}}</div>
                    <div class='font-weight-bold mr-2' v-if="datas.codes_panne[index].pivot.custom_txt">{{datas.codes_panne[index].pivot.custom_txt}}</div>
                </v-row>

            </v-col>
            <v-divider class='mb-2' ></v-divider>
        </div>
              <v-divider class='mb-2' ></v-divider> 
              <v-textarea v-if='![2,4,8,9,10,11].includes(datas.inter_type_cr)'
                background-color="grey lighten-2"
                label="Observations Techniques"
                v-model='obsTechnique'
                class='ma-2 mb-0'
              ></v-textarea>
        <v-container>
            <cx-button label='VOIR HISTORIQUE TECHNIQUE' @click='historique_technique'></cx-button>
            <div class='d-flex justify-space-between'>
                <cx-button label='PRECEDENT' @click='previous'></cx-button>
                <cx-button label='SUIVANT' @click='next'></cx-button>
            </div>
        </v-container>
    </v-container>   
    
</div>
</template>
<script>
    export default {
        name: 'vx-recapitulatif-operations',
        components: {
            'CxButton': () => import('../../components/action/CxButton')
        },
        props : {
            dataProvider: {}
        },
        data() {
            return {
                datas : null,
                obsTechnique : ''
            }
        },
        mounted(){
            this.$services.request.get('api/pwa/mes-interventions/intervention/' + this.dataProvider.intervention + '/operations-techniques').then((response)=>{
                this.datas = response
                if (this.datas.observation_technique) {
                    this.obsTechnique = this.datas.observation_technique.commentaire_texte
                }
            })
            .catch(()=> {
                this.$services.powerLib.notification.showSnackbar({
                            message : 'Une erreur est survenue lors de la récupération des informations',
                            type : 'error'
                })
            })
        },
        methods: {
            historique_technique(){
                this.$store.commit('TAB_COMPONENT', {   component: 'vx-historique-technique', 
                                                        title: 'Intervention ' + this.dataProvider.intervention,
                                                        dataProvider: { "intervention" : this.dataProvider.intervention }
                                                    });
            },
            next(){

                let data = {
                    observation_technique : this.obsTechnique
                }
                this.$services.request.post('api/pwa/mes-interventions/intervention/' + this.dataProvider.intervention + '/save-observation-technique', data)

                this.$store.commit('TAB_COMPONENT', {   component: 'vx-gestionnaire-photo', 
                                                        title: 'Intervention ' + this.dataProvider.intervention,
                                                        dataProvider: { "intervention" : this.dataProvider.intervention }
                                                    });
            },
            previous(){
                this.$store.commit('TAB_COMPONENT', {   component: 'vx-logements', 
                                                        title: 'Intervention ' + this.dataProvider.intervention,
                                                        dataProvider: { "intervention" : this.dataProvider.intervention }
                                                    });
            }
        }
    }
</script>

<script>

    /**
     * Ce composant gère l'envoie d'une notification à l'utilisation afin de lui transmettre un message
     * Utilisation :
     * Source : https://vuetifyjs.com/en/components/snackbars/#usage
     * Appel : this.$services.powerLib.notification.(error || success || info)();
     * 1er argument : Le message
     * 2ème : La position de la notification
     * 3ème : La durée de la notifiication ( Tips : Mettre -1 pour l'afficher sans durée de temps )
     */
    export default {

        component: null,

        type: {
            error: {snackbarColor: '#e41319', textColor: '#FFF', buttonColor: '#FFF'},
            success: {snackbarColor: '#4D8B31', textColor: '#FFF', buttonColor: '#FFF'},
            info: {snackbarColor: '#F3A712', textColor: '#000', buttonColor: '#FFF'}
        },
        
        init(component){
            this.component = component;
        },


        /**
         * Gère l'affichage de la notification
         *
         * FEATURE POSSIBLES :
         * Notification empilable ?
         * Fermeture forcé via une méthode ?
         * Callback ?
         * @param params
         */
        showSnackbar({message= 'Placeholder',
                        timeout= 4500,
                        position= 'top',
                        type= 'info'}) {

            this.component.update({...this.type[type],message,timeout,position})
            
        }
    }
</script>
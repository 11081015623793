<template>
    <div>
        <v-toolbar-title class='global-main-title'>Risques</v-toolbar-title>
        <div class='d-flex justify-space-around'>
            <cx-button label='PRECEDENT' @click='previous'></cx-button>
            <cx-button label='SIGNALER RISQUE' @click='displayFormCreate = true'></cx-button>
        </div>
        <!-- Form Création Risque -->
        <v-dialog v-if="!loading" v-model="displayFormCreate" persistent max-width="500">
            <v-card>
                <v-card-title class='global-main-title'>
                    Signalement Risque
                </v-card-title>
                <v-card-text style='font-size:22px;text-align:start;' class='pt-12'>
                    <v-form ref="form">
                        <v-select v-model="formData.selectedTypeRisque" :items="type_risques" label="Type" item-value="id"
                            item-text="libelle" :rules="[v => !!v || 'Renseigner le type de risque']"></v-select>
                        <v-select v-model="formData.selectedTypeUrgence" :items="type_urgences" label="Urgence"
                            item-value="id" item-text="libelle"
                            :rules="[v => !!v || 'Renseigner le niveau d\'urgence']"></v-select>
                        <v-textarea v-model="formData.commentaire_texte" label="Commentaire"
                            :rules="[v => !!v || 'Renseigner un commentaire']">text</v-textarea>
                        <div class="text-right">
                            <v-btn color="red" tonal @click="displayFormCreate = false" class="mr-2">Annuler</v-btn>
                            <v-btn color="success" tonal @click="createRisque"
                                :disabled="(!formData.commentaire_texte || !formData.selectedTypeUrgence || !formData.selectedTypeRisque)">Ajouter</v-btn>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <!-- Card Risques Identifiés  -->
        <div class="d-flex flex-column">
            <v-toolbar-title class='global-main-title mx-5 mt-5'>{{ dataProvider.batiment.hierarchie_code + ' ' + dataProvider.batiment.hierarchie_libelle }}</v-toolbar-title>


            <v-card v-if="!loading" v-for="(risque, index) in risques" :key="index" color="#f0f5fb" elevation="0" tile>
                <v-card-title>
                    <h6>{{ risque.updated_at_formated }} - {{ risque.upadated_by_username ? risque.upadated_by_username
                        : risque.created_by_username }} </h6>
                </v-card-title>
                <v-card-subtitle>
                    <div>
                        Urgence :
                        <span :class="getTextColorClass(risque.libelle_type_urgence)">
                            {{ risque.libelle_type_urgence }}
                        </span>
                    </div>
                    <div>
                        Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {{ risque.libelle_type_risque }}
                    </div>
                    <div text-color="red">
                        Etat&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {{ risque.libelle_etat_risque }}
                    </div>
                </v-card-subtitle>
                <v-card-text>
                    <div>Commentaire :</div>
                    <h3>{{ risque.commentaire.commentaire_texte }}</h3>
                </v-card-text>
                <v-card-actions>
                    <!-- <v-btn>Photos</v-btn> -->
                </v-card-actions>
                <v-divider></v-divider>
            </v-card>
        </div>
        <div v-if="loading">
            <div v-for="id of [1, 2, 3, 4]" v-bind:key="id">
                <v-skeleton-loader type="card">
                </v-skeleton-loader>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: 'vx-fiche-infos-utiles-batiment',
    props: {
        dataProvider: {}
    },
    data() {
        return {
            formData: {
                selectedTypeRisque: null,
                selectedTypeUrgence: null,
                commentaire_texte: ''
            },
            type_risques: {},
            // type_risques: [
            //     { id: 1, libelle: 'Plain-pied' },
            //     { id: 2, libelle: 'Gaz' },
            //     { id: 3, libelle: 'Travail en hauteur' },
            //     { id: 4, libelle: 'Produits dangereux' },
            //     { id: 5, libelle: 'Environnement' },
            //     { id: 6, libelle: 'Autre' }
            // ],
            type_urgences: {},
            // type_urgences: [
            //     { id: 1, libelle: 'Faible' },
            //     { id: 2, libelle: 'Moyenne' },
            //     { id: 3, libelle: 'Forte' }
            // ],
            loading: false,
            commentaire_texte: null,
            risqueSelected: null,
            displayFormCreate: false,
            risques: {},
            techName: this.$store.getters.getUserInfos().name
        }
    },

    components: {
        'CxButton': () => import('../../components/action/CxButton')
    },

    mounted() {
        this.getRisques()
        this.getTypeRisques()
        this.getTypeUrgences()
    },

    methods: {
        getTypeRisques() {
            this.loading = true
            this.$services.request.get('api/pwa/mes-batiments/type-risques')
                .then((response) => {
                    this.loading = false
                    this.type_risques = response;
                }).catch(() => {
                    this.$services.powerLib.notification.showSnackbar({
                        message: 'Une erreur est survenue lors de la récupération des risques identifiés',
                        type: 'error'
                    })
                    this.loading = false
                })
        },
        getTypeUrgences() {
            this.loading = true
            this.$services.request.get('api/pwa/mes-batiments/type-urgences')
                .then((response) => {
                    this.loading = false
                    this.type_urgences = response;
                }).catch(() => {
                    this.$services.powerLib.notification.showSnackbar({
                        message: 'Une erreur est survenue lors de la récupération des risques identifiés',
                        type: 'error'
                    })
                    this.loading = false
                })
        },
        getRisques() {
            this.loading = true
            this.$services.request.get(`api/pwa/mes-interventions/batiment/${this.dataProvider.batiment.hierarchie_id}/risques`)
                .then((response) => {
                    this.loading = false
                    this.risques = response;
                }).catch(() => {
                    this.$services.powerLib.notification.showSnackbar({
                        message: 'Une erreur est survenue lors de la récupération des risques identifiés',
                        type: 'error'
                    })
                    this.loading = false
                })
        },
        createRisque() {
            this.loading = true
            this.$services.request.post(`/api/pwa/mes-interventions/batiment/${this.dataProvider.batiment.hierarchie_id}/risques`, this.formData).then(() => {
                this.$services.powerLib.notification.showSnackbar({
                    message: 'Le signalement a bien été créé',
                    type: 'success'
                })
                this.loading = false
            }).catch(() => {
                this.$services.powerLib.notification.showSnackbar({
                    message: 'Une erreur est survenue lors de la création du signalement',
                    type: 'error'
                })
                this.loading = false
            })
            this.$refs.form.reset();
            this.displayFormCreate = false;
        },
        previous() {
            this.$store.commit('TAB_COMPONENT', {
                component: 'vx-mes-batiments',
                title: 'Mes Bâtiments',
                dataProvider: { "intervention": this.dataProvider.intervention }
            })

        },
        // fonction permettant de changer la couleur du text "type_urgences"
        getTextColorClass(typeUrgence) {
            switch (typeUrgence) {
                case 'Faible':
                    return 'text-yellow';
                case 'Moyenne':
                    return 'text-orange';
                case 'Forte':
                    return 'text-red';
                default:
                    return '';
            }
        },
    },
}

</script>
<style>
.text-yellow {
    color: green;
}

.text-orange {
    color: darkorange;
}

.text-red {
    color: red;
}
</style>
<template>
        <div>
            <div style='text-align:center;background-color:lightgrey' class='pt-2 pb-2 mb-2'>{{dataProvider.libelle}}</div>
            <div v-if='photo'>
                <v-img  width="100%" :src="'data:image/jpeg;base64,'+ photo" aspect-ratio="2" contain></v-img>
                <div style="text-align: center">
                    <v-btn @click="clear" class="pr-5" icon small dark>
                        <v-icon  @click="edit" color="orange">edit</v-icon>
                    </v-btn>
                </div>
            </div>
            
            <div v-else style="text-align: center">
                <vueSignature  id="signature" ref="signature" :sigOption="option" :w="'100%'" :h="'250px'"></vueSignature>
                <br>
                <div style="text-align: center" v-if="!loading">
                    <v-btn @click="clear" class="pr-5" icon small dark>
                        <v-icon  color="red">clear</v-icon>
                    </v-btn>
                    <v-btn @click="save" class="pl-5" icon small dark>
                        <v-icon  color="green">check</v-icon>
                    </v-btn>
                </div>
                <div style="text-align: center" v-else>
                    <v-progress-circular indeterminate color="orange"></v-progress-circular>
                </div>
            </div>     
        </div>
</template>


<script>

  export default {
        name: "cx-signature",
        props: {
            intervention: {
                type: Number
            },
            dataProvider: {
                type: Object
                /*
                {
                    libelle: '',
                    id: ''
                }
                */
            }
        },
        data(){
            return {
                option:{
                    penColor:"rgb(0, 0, 0)",
                    backgroundColor:"rgb(255,255,255)"
                },
                photo: '',
                loading: false,
                
            }
        },
        mounted() {
            this.$services.request.get('api/pwa/mes-interventions/signature/'+ this.intervention +'?filename='+this.getNomenclatureSignaturePictureName())
                .then((response)=>{
                    this.photo = response
                    if (this.photo) {
                        this.$emit('change', this.getNomenclatureSignaturePictureName());
                    }
                })
        },
        methods: {
            save() {
                this.loading = true;
                
                const byteCharacters = atob(this.$refs.signature.save().replace('data:image/png;base64,',''));
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: 'image/png'});

                let data = {
                    files: blob,
                    filename: this.getNomenclatureSignaturePictureName(),
                    intervention: this.intervention
                }
                
                this.$services.request.post('api/pwa/mes-interventions/signature', data)
                    .then((response)=>{
                        if(response){
                            this.$services.request.get('api/pwa/mes-interventions/signature/'+ this.intervention +'?filename='+this.getNomenclatureSignaturePictureName())
                            .then((response)=>{
                                this.photo = response
                                this.$emit('change', this.getNomenclatureSignaturePictureName());
                                this.loading = false;
                            })
                            .catch( () => {
                                this.$services.powerLib.notification.showSnackbar({
                                    message : 'Une erreur est survenue lors de la récupération de la signature',
                                    type : 'error'
                                })
                                this.loading = false;
                            })
                        }
                    })
                    .catch( () => {
                        this.$services.powerLib.notification.showSnackbar({
                            message : 'Une erreur est survenue lors du téléchargement',
                            type : 'error'
                        })
                        this.loading = false;
                    })

            },
            clear() {
                this.$refs.signature.clear()
            },
            edit() {
                this.photo = ''
            },
            getNomenclatureSignaturePictureName(){
                return this.intervention + '_' + this.dataProvider.identifiant_CRI + '_signature.png'
            }
        }
    }
</script>

<style scoped>
    #signature {
        border: double 3px transparent;
        border-radius: 5px;
        background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, #ef6c00, #ef6c00);
        background-origin: border-box;
        background-clip: content-box, border-box;
    }
</style>

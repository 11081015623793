<template>
    <div>
        <v-toolbar-title class='global-main-title' >Photos</v-toolbar-title>
        <div v-for="(appareil, index) in datas.appareils" :key="index">
            <v-toolbar-title class='global-main-title mx-5 mt-5' >{{appareil.produit.produit_code}} - {{appareil.produit.produit_libelle}}</v-toolbar-title>
            <cx-update-appareils :intervention_id="dataProvider.intervention" :appareil_id="appareil.appareil_id" type="demande_modification"></cx-update-appareils>
            <cx-document-galerie :id="appareil.appareil_id" model="appareils" type="pre_intervention" class='mt-2'></cx-document-galerie>
        </div>
        <div>
            <v-toolbar-title class='global-main-title mx-5 mt-2'  >Intervention</v-toolbar-title>
            <cx-document-galerie class='mt-5' :id="this.dataProvider.intervention" model="interventions" type="pre_intervention"></cx-document-galerie>
        </div>
        <div class='d-flex justify-space-around'>
            <cx-button label='PRECEDENT' @click='previous'></cx-button>
            <cx-button label='SUIVANT' @click='next'></cx-button>
        </div>
    </div>
</template>
<script>

    
    export default {
        name: 'vx-gestionnaire-photo',
        components: {
            'CxButton': () => import('../../components/action/CxButton'),
            'CxDocumentGalerie': () => import('../../components/action/CxDocumentGalerie'),
            'CxUpdateAppareils': () => import('../../components/action/CxUpdateAppareils')
        },
        props : {
            dataProvider: {}
        },
        data() {
            return {
                datas : {},
                files : [],
                commentaire :''
            }
        },
        mounted(){

            this.$services.request.get('api/pwa/mes-interventions/intervention/' + this.dataProvider.intervention + '/operations-techniques').then((response)=>{
                this.datas = response
            }).catch(()=> {
                this.$services.powerLib.notification.showSnackbar({
                            message : 'Une erreur est survenue lors de la récupération des informations',
                            type : 'error'
                })
            })
        },
        methods:{
            previous(){
                this.$store.commit('TAB_COMPONENT', {   component: 'vx-recapitulatif-operations', 
                                                        title: 'Intervention ' + this.dataProvider.intervention,
                                                        dataProvider: { "intervention" : this.dataProvider.intervention }
                                                    });
            },
            next(){
                this.$store.commit('TAB_COMPONENT', {   component: 'vx-scenarii', 
                                                        title: 'Intervention ' + this.dataProvider.intervention,
                                                        dataProvider: { "intervention" : this.dataProvider.intervention }
                                                    });
            }
        }
    }
</script>

<template>
    <div id="app">
        <v-app class="app-container">
            <cx-power-snack-bar ref='snackbar' style='margin-top:50px;margin-bottom:50px;'></cx-power-snack-bar>
            <keep-alive>
                <router-view/>
            </keep-alive>
        </v-app>
    </div>
</template>
<script>

    import CxPowerSnackBar from './components/message/CxPowerSnackBar'
    
    export default {
        components: {
            CxPowerSnackBar
        },
        mounted() {
            this.$services.powerLib.notification.init(this.$refs.snackbar)
        }
    }
</script>
<style lang="scss">
    #app {
        background: #f0f5fb;
    }
</style>




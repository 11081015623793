<template>
    <div>
        <v-toolbar-title class='global-main-title'>Fiche Infos Utiles</v-toolbar-title>
        <div class='d-flex justify-space-around'>
            <cx-button label='PRECEDENT' @click='previous'></cx-button>
            <cx-button label='AJOUTER INFO' @click='displayFormCreate = true'></cx-button>
        </div>
        <!-- Form Création Info Utile -->
        <v-dialog v-if="!loading" v-model="displayFormCreate" persistent max-width="500">
            <v-card>
                <v-card-title class='global-main-title'>
                    Création Info Utile
                </v-card-title>
                <v-card-text style='font-size:22px;text-align:start;' class='pt-12'>
                    <v-form ref="form">
                        <v-textarea v-model="commentaire_texte" label="Commentaire">text</v-textarea>
                        <div class="text-right">
                            <v-btn color="red" tonal @click="displayFormCreate = false" class="mr-2">Annuler</v-btn>
                            <v-btn color="success" tonal @click="createInfoUtile" :disabled="!commentaire_texte">Ajouter</v-btn>
                            <!-- <cx-upload-photo>photo</cx-upload-photo> -->
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Form Modification Info Utile -->
        <v-dialog v-if="!loading" v-model="displayFormEdit" persistent max-width="500">
            <v-card>
                <v-card-title class='global-main-title'>
                    Modification Info Utile
                </v-card-title>
                <v-card-text style='font-size:22px;text-align:start;' class='pt-12'>
                    <v-form v-if='infoUtileSelected' @submit.prevent="updateInfoUtile" ref="form">
                        <v-textarea v-model="infoUtileSelected.commentaire.commentaire_texte"
                            label="Commentaire">text</v-textarea>    
                        <div class="text-right">    
                        <v-btn color="red" tonal @click="displayFormEdit = false" class="mr-2">Annuler</v-btn>
                        <v-btn type="submit" color="success" tonal :disabled="!infoUtileSelected.commentaire.commentaire_texte">Modifier</v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Card Infos Utiles  -->
        <div class="d-flex flex-column">
            <v-toolbar-title class='global-main-title mx-5 mt-5'>{{ dataProvider.batiment.hierarchie_code + ' ' + dataProvider.batiment.hierarchie_libelle }}</v-toolbar-title>


            <v-card v-if="!loading" v-for="(infoUtile, index) in infosUtiles" :key="index" color="#f0f5fb" elevation="0" tile>
                <v-card-text>
                    {{ infoUtile.updated_at_formated }} - {{ infoUtile.upadated_by_username ? infoUtile.upadated_by_username
                        : infoUtile.created_by_username }}
                </v-card-text>
                <v-card-text>
                    <h3>{{ infoUtile.commentaire.commentaire_texte }}</h3>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="isCreated(infoUtile.created_by_username, infoUtile.updated_by_username)"
                        @click="editInfoUtile(infoUtile)">Edit</v-btn>
                    <!-- <v-btn>Photos</v-btn> -->
                </v-card-actions>
                <v-divider></v-divider>
            </v-card>
        </div>
        <div v-if="loading">
            <div v-for="id of [1, 2, 3, 4]" v-bind:key="id">
                <v-skeleton-loader type="card">
                </v-skeleton-loader>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: 'vx-fiche-infos-utiles-batiment',
    props: {
        dataProvider: {}
    },
    data() {
        return {
            loading: false,
            commentaire_texte: null,
            infoUtileSelected: null,
            displayFormCreate: false,
            displayFormEdit: false,
            infosUtiles: {},
            techName: this.$store.getters.getUserInfos().name
        }
    },

    components: {
        'CxButton': () => import('../../components/action/CxButton'),
        // 'CxUploadPhoto': () => import('../../components/action/CxUploadPhoto.vue')
    },

    mounted() {
        this.getInfosUtiles()
    },

    methods: {
        getInfosUtiles() {
            this.loading = true
            this.$services.request.get(`api/pwa/mes-interventions/batiment/${this.dataProvider.batiment.hierarchie_id}/infos`)
            .then((response) => {
                this.loading = false
                this.infosUtiles = response;
                console.log("response", response);
            }).catch(() => {
                this.$services.powerLib.notification.showSnackbar({
                    message: 'Une erreur est survenue lors de la récupération des infos utiles',
                    type: 'error'
                })
                this.loading = false
            })
        },

        editInfoUtile(infoUtile) {
            this.infoUtileSelected = infoUtile;
            this.displayFormEdit = true;
        },

        updateInfoUtile() {
            this.loading = true
            let data = {
                'id': this.infoUtileSelected.id,
                'commentaire_id': this.infoUtileSelected.commentaire.commentaire_id,
                'commentaire_texte': this.infoUtileSelected.commentaire.commentaire_texte,
                'created_by': this.infoUtileSelected.created_by

            }

                this.$services.request.put(`/api/pwa/mes-interventions/batiment/${this.dataProvider.batiment.hierarchie_id}/infos/${this.infoUtileSelected.commentaire_id}`, data).then(() => {
                    this.$services.powerLib.notification.showSnackbar({
                        message: 'Le commentaire a bien été modifié',
                        type: 'success'
                    })
                    this.loading = false
                }).catch(() => {
                    this.$services.powerLib.notification.showSnackbar({
                        message: 'Une erreur est survenue lors de la modification',
                        type: 'error'
                    })
                    this.loading = false
                })

                this.displayFormEdit = false;
        },
        createInfoUtile() {
            this.loading = true
            let data = { 'commentaire_texte': this.commentaire_texte }

            this.$services.request.post(`/api/pwa/mes-interventions/batiment/${this.dataProvider.batiment.hierarchie_id}/infos`, data).then(() => {
                this.$services.powerLib.notification.showSnackbar({
                    message: 'Le commentaire a bien été créé',
                    type: 'success'
                })
                this.loading = false
            }).catch(() => {
                this.$services.powerLib.notification.showSnackbar({
                    message: 'Une erreur est survenue lors de la création du commentaire',
                    type: 'error'
                })
                this.loading = false
            })
            this.$refs.form.reset();
            this.displayFormCreate = false;
        },

        previous() {
            this.$store.commit('TAB_COMPONENT', {
                component: 'vx-mes-batiments',
                title: 'Mes Bâtiments',
                dataProvider: { "intervention": this.dataProvider.intervention }
            })

        },
        // fonction permettant de rentre editable pour le tech 
        isCreated(created_by_username, updated_by_username) {
            return created_by_username == this.techName && (updated_by_username == this.techName || updated_by_username == null);
        },
    }
}

</script>
<template>
        <v-select :value="dataProvider.data" :items="item" :label="dataProvider.libelle" @change='emit'></v-select>
</template>


<script>

  export default {
        name: "cx-select",
        data(){
            return {
                item : []
            }
        },
        props: {
            dataProvider: {
                type: Object
                /*
                {
                    choices: '',
                    libelle: ''
                }
                */
            }
        },
        mounted(){
            this.item = this.dataProvider.choices.map((item)=>{
                return {text:item.name,value:item.value}
            })
        },
        methods: {
            emit(value){
                return this.$emit('change',value)
            }
        }
    }
</script>

<template>
    
<v-layout class='d-flex ma-5 pa-5'>
        <v-flex>
            <v-form ref="updatePasswordForm">
                <v-text-field
                    v-model="password"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    name="password"
                    label="Entrez le nouveau mot de passe"
                    hint="Au moins 12 caractères"
                    :append-icon="show ? 'visibility_off' : 'visibility'"
                    @click:append="show = !show"
                    counter
                ></v-text-field>

                <v-text-field
                    v-model="rePassword"
                    :rules="[rules.required, ruleSamePwd]"
                    :type="show ? 'text' : 'password'"
                    name="re-password"
                    label="Entrez une deuxieme fois le nouveau mot de passe"
                    hint="Au moins 12 caractères"
                    :append-icon="show ? 'visibility_off' : 'visibility'"
                    @click:append="show = !show"
                    counter
                ></v-text-field>
            </v-form>

            <cx-button @click="updatePassword" text :disabled="disableButton" label="Enregistrer"/>
        </v-flex>
</v-layout>
</template>
<script>

    export default {
        name: 'vx-update-Password',
        components: {
            'CxButton': () => import('../../components/action/CxButton')
        },
        data() {
            return {
                password: '',
                rePassword: '',
                rules: {
                    required: v => !!v || 'Required.',
                    min: v => this.$services.powerLib.regex.getRegex('mdp').test(v) || "Le mot de passe doit être min. de 12 caractères. Contenir un chiffre et un caractère spécial.",
                },
                show: false,
            }
        },
        computed: {
            disableButton() {
                if(this.password != '' && this.rePassword != ''){
                    return this.password != this.rePassword
                } else {
                    return true
                }
            },
            ruleSamePwd(){
                return (this.password != this.rePassword) ? 'Les mots de passe doivent être identiques' : true
            }
        },
        methods: {
            updatePassword() {
                if(this.$refs.updatePasswordForm.validate()) {
                    this.$services.request.post(`api/pwa/general/password`, {password: this.password}).then((response) => {
                        this.$services.powerLib.notification.showSnackbar({
                            message : response.message,
                            type : 'success'
                        })
                        this.password = ''
                        this.rePassword = ''
                    })
                }

            }
        }
    }
</script>

<template>
    <div>
        <v-toolbar-title class='global-main-title' >Clôture</v-toolbar-title>
        <v-select class='ma-10' :items="codes_cloture" label="Code Clôture" @change='change'></v-select>
        <v-toolbar-title class='global-main-title' >Photo</v-toolbar-title>
        <cx-upload-photo :id='this.dataProvider.intervention' model='interventions' type='intervention_impossible' class='mt-5'></cx-upload-photo>
        <v-textarea 
            background-color="grey lighten-2"
            label="Observations Intervention"
            v-model='obsIntervention'
            class='ma-5'
        ></v-textarea>
        <v-container class='pt-6 px-6'>
            <div v-if='loading' style="display:flex;justify-content:center;align-items:center;"><v-progress-circular indeterminate color="orange"></v-progress-circular></div>
            <div v-else class='d-flex justify-space-around'>
                <cx-button label='PRECEDENT' @click='previous'></cx-button>
                <cx-button label='CLOTURER' @click='cloture' :disabled="!displayBtn"></cx-button>
            </div>
        </v-container>
    </div>
</template>
<script>

    export default {
        name: 'vx-intervention-impossible',
        components: {
            'CxButton': () => import('../../components/action/CxButton'),
            'CxUploadPhoto': () => import('../../components/action/CxUploadPhoto')
        },
        props : {
            dataProvider: {}
        },
        data(){
            return {
                obsIntervention : '',
                files : '',
                select_code_cloture: '',
                displayBtn : false,
                codes_cloture: [
                    {value : 300,text: '300 - ANNULATION: INTER A ARCHIVER'},
                    {value : 310,text: '310 - ABONNE ABSENT'},
                    {value : 320,text: '320 - ABONNE INTROUVABLE'},
                    {value : 330,text: '330 - REFUS ABONNE'},
                    {value : 340,text: '340 - PROBLEME REGLE'},
                    {value : 350,text: '350 - IMPOSSIBILITE TECHNIQUE'},
                    {value : 360,text: '360 - HORS CONTRAT'}
                ],
                loading: false
            }
        },
        methods:{
            change(value){
                this.select_code_cloture = value
                this.displayBtn = true
            },
            previous(){
                this.$store.commit('TAB_COMPONENT', {   component: 'vx-logements', 
                                                        title: 'Intervention ' + this.dataProvider.intervention,
                                                        dataProvider: { "intervention" : this.dataProvider.intervention }
                                                    });
            },
            cloture(){
                this.loading = true

                let data = {
                    inter_id : this.dataProvider.intervention,
                    obsIntervention : this.obsIntervention,
                    model : 'interventions',
                    files: this.files,
                    type: 'intervention_impossible',
                    cloture: this.select_code_cloture
                }

                this.$services.request.post('api/pwa/mes-interventions/intervention',data).then(()=>{
                    
                    this.$services.powerLib.notification.showSnackbar({
                                message : 'La clôture a bien été effectuée',
                                type : 'success'
                    })

                    this.$store.commit('TAB_COMPONENT', {component: 'vx-interventions', title: 'Mon Activité'})

                }).catch(()=> {
                    this.loading = false
                    this.$services.powerLib.notification.showSnackbar({
                                message : 'Une erreur est survenue lors de la clôture',
                                type : 'error'
                    })
                })
            },
        }
    }
</script>

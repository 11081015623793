<script>
    import moment from 'moment'
    moment.locale('fr')

    export default {
        getTodayBdd () {
            return moment().format('YYYY-MM-DD')
        },
        getDate (date, format = 'DD/MM/YYYY') {
            return moment(date).format(format)
        },
        addDate(date, nbDays, format = 'YYYY-MM-DD') {
            return moment(date).add(nbDays, 'd').format(format)
        },
        getLibelleSegmentHoraire (tranche) {
            switch (parseInt(tranche)) {
                case 1:
                    return 'Début de matinée'
                case 2:
                    return 'Matinée'
                case 3:
                    return 'Fin de matinée'
                case 4:
                    return 'Journée'
                case 5:
                    return 'Début d\'après-midi'
                case 6:
                    return 'Après-midi'
                case 8:
                    return 'Fin d\'après-midi'
                default:
                    return ''
            }
        }
    }
</script>

<template>
    <div>
        <div style='text-align:center;background-color:lightgrey' class='pt-2 pb-2'>{{dataProvider.libelle}}</div>
        <hr>        
        <v-textarea
                    outlined
                    v-model="dataProvider.data"
                    @change='emit'
            ></v-textarea>
    </div>
</template>


<script>

  export default {
        name: "cx-text-area",
        props: {
            dataProvider: {
                type: Object
                /*
                {
                    label: '',
                    data: ''
                }
                */
            }
        },
        methods: {
            emit(){
                return this.$emit('change',this.dataProvider.data)
            }
        }
    }
</script>

<template>
        <v-card class="d-flex" flat >
            <div style='text-align:center;background-color:lightgrey;min-width:200px;' class='pt-6 pz-2'>{{dataProvider.libelle}}</div>
            <v-text-field
                    v-model="dataProvider.data"
                    type="number"
                    class='px-5'
                    style='text-align:center;max-width:100px;'
                    @change='emit'
                    @keyup="emit"
            ></v-text-field>
        </v-card>
</template>


<script>

  export default {
        name: "cx-number",
        props: {
            dataProvider: {
                type: Object
                /*
                {
                    label: '',
                    data:''
                }
                */
            }
        },
        methods: {
            emit(){
                return this.$emit('change',this.dataProvider.data)
            }
        }
    }
</script>

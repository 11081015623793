<template>
    <div class="d-flex flex-column justify-center">
        <!-- numéro utile plus utilisé
        <v-card
            class="flex-grow-1 mx-auto ma-5 ml-4 mr-4 align-self-center width"
            color="grey"
        >
            <div>
                <v-btn large block color="grey" max-width="500" @click="openComponent(0)" dark>Numéros Utiles</v-btn>
            </div>
        </v-card>
        -->
        <v-card
            class="flex-grow-1 mx-auto ma-5 ml-4 mr-4 align-self-center width"
            color="grey"
        >
            <div>
                <v-btn large block color="grey" max-width="500" @click="openComponent(1)" dark>Guide Utilisateur</v-btn>
            </div>
        </v-card>
        <v-card
            class="flex-grow-1 mx-auto ma-5 ml-4 mr-4 align-self-center width"
            color="grey"
        >
            <div>
                <v-btn large block color="grey" max-width="500" @click="openComponent(2)" dark>Vidéo De Présentation</v-btn>
            </div>
        </v-card>
    </div>
</template>
<script>

    
    export default {
        name: 'vx-documents',

        methods: {
            openComponent(n) {
                switch (n) {
                    case 0:
                        //numéro utiles plus utilisé dans l'immédiat
                        this.$store.commit('TAB_COMPONENT', {component: 'vx-numeros-utiles', title: 'Numeros utiles'});
                        break;
                    case 1:
                        this.$services.request.openUrl('/api/pwa/mes-documents/guide-utilisateur');
                        break;
                    case 2:
                        this.$services.powerLib.notification.showSnackbar({
                            message : 'Fonctionnalité en développement :D !',
                            type : 'info'
                        })
                        break;
                }
            },
        },
    }
</script>

<style lang="scss">
    .width {
        min-width: fill-available;
        min-width: -moz-available;
        min-width: -webkit-fill-available;
    }
</style>
